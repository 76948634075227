:root {
  --bg-color: #1a1a1a;
  --text-color: #ffffff;
  --accent-color: #ff69b4;
  --button-hover: #ff1493;
}

body {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  margin: 0;
  background-color: var(--bg-color);
  color: var(--text-color);
  transition: background-color 0.3s, color 0.3s;
}

.container {
  text-align: center;
  padding: 20px;
  max-width: 800px;
  width: 90%;
  margin: 0 auto;
}

h1 {
  font-size: clamp(2rem, 8vw, 4rem);
  color: var(--accent-color);
  text-shadow: 2px 2px 4px rgba(255,255,255,0.1);
  margin-bottom: 30px;
}

.image-container {
  position: relative;
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vw;
  max-height: 400px;
}

#miaouwImg {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  border-radius: 15px;
  box-shadow: 0 8px 16px rgba(0,0,0,0.2);
  transition: transform 0.3s ease;
}

#miaouwImg:hover {
  transform: scale(1.02);
}

.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
}

.loading-spinner {
  color: var(--accent-color);
  font-size: clamp(2rem, 5vw, 3rem);
}

button {
  margin-top: 30px;
  padding: 12px 24px;
  font-size: clamp(1rem, 3vw, 1.2rem);
  background-color: var(--accent-color);
  color: var(--text-color);
  border: none;
  border-radius: 25px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
}

button:hover {
  background-color: var(--button-hover);
  transform: translateY(-2px);
}

button:active {
  transform: translateY(0);
}

.ds-toggle {
  position: fixed;
  top: 20px;
  right: 20px;
  background: none;
  border: none;
  color: var(--text-color);
  font-size: 1.5rem;
  cursor: pointer;
  z-index: 1000;
}

#miaouwCounter {
  font-size: 1.2rem;
  margin-top: 20px;
}

@media (max-width: 600px) {
  .container {
    width: 95%;
  }
  
  h1 {
    margin-bottom: 20px;
  }
  
  .image-container {
    height: 70vw;
  }
  
  button {
    padding: 10px 20px;
  }
  
  .ds-toggle {
    top: 10px;
    right: 10px;
    font-size: 1.2rem;
  }
}

@media (max-width: 400px) {
  .image-container {
    height: 80vw;
  }
}